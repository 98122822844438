@keyframes shake-animation {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}

.default-background {
  background: transparent;
  transition: background 0.5s ease;
}

.highlight-background {
  background: #ffd4d4;
  transition: background 0.5s ease;

  span {
    animation: shake-animation 1s infinite;
  }
}

.highlight-label {
  background: #ffd4d4;
  transition: background 1s ease;
  animation: shake-animation 1s ease;
}

.dotsWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-width: 960px;
  @media (max-width: 900px) {
    height: 100vh;
  }

  div, p, button {
    font-family: 'Gilroy', sans-serif;
  }
}

div.leaflet-top {
  .leaflet-routing-alt {
    table {
      display: none;
    }
  }
}

.header-with-items {
  padding: 0 10px 10px 10px;
  border-radius: 4px;
  margin-bottom: 10px;
  transition: all 0.1s ease-in-out;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.header-with-items:hover {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.backoffice-item {
  background-color: #ffffff;
  margin-left: 20px;
  padding: 4px 8px;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: transform 0.1s ease;
  margin-bottom: 6px;
}

.backoffice-item:hover {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}